export const BLOCK_TIME = {
  17001: 2500,
  31337: 1200,
  33784: 1200
}

export const TRANSFER_GAS = {
  17001: '0.01',
  31337: '2',
  33784: '2'
}
  
